import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { maxW } from "../../styles/mediaQueries"

const CollageTwoRows = ({ imgs }) => {
    const { topImage, bottomLeftImage, bottomRightImage } = imgs
    return (
        <CollageContainer>
            <TopImg fluid={topImage.fluid} alt={topImage.description} />
            <BottomLeftImg
                fluid={bottomLeftImage.fluid}
                alt={bottomLeftImage.description}
            />
            <BottomRightImg
                fluid={bottomRightImage.fluid}
                alt={bottomRightImage.description}
            />
        </CollageContainer>
    )
}

export default CollageTwoRows

const CollageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${maxW[0]} {
        flex-direction: column;
    }
`
const TopImg = styled(Img)`
    width: 100%;
    margin-bottom: 10px;
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
    }
`
const BottomLeftImg = styled(Img)`
    width: 30%;
    margin-right: 10px;
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
        margin-right: 0;
        margin-bottom: 10px;
    }
`
const BottomRightImg = styled(Img)`
    width: calc(70% - 10px);
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
    }
`
