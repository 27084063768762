import React, { useState } from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { CarouselP } from "../../Partials/TextPieces"
import { maxW } from "../../../styles/mediaQueries"

const CarouselImage = ({ img, showText, copy, description }) => {
    const [showMask, toggleShowMask] = useState(false)

    const toggleText = () => {
        toggleShowMask(!showMask)
    }

    return (
        <ImageContainer onPointerEnter={toggleText} onPointerLeave={toggleText}>
            <Img fluid={img} alt={description} />
            {(showMask || showText) && (
                <TextMask>
                    <CarouselP>{copy.toUpperCase()}</CarouselP>
                </TextMask>
            )}
        </ImageContainer>
    )
}

export default CarouselImage

const ImageContainer = styled.div`
    width: 381px;
    margin: 0 10px;
    position: relative;
    min-width: 381px;
    will-change: transform, visibility;
    ${maxW[1]} {
        width: 48%;
        min-width: 0;
        display: flex;
        flex-direction: column;
        margin: 0 0 45px 0;
    }
    ${maxW[0]} {
        width: 100%;
    }
`

const TextMask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(7, 7, 7, 0.7),
        rgba(11, 11, 11, 0.7)
    );
    z-index: 1;
    display: flex;
    top: 0;
    ${maxW[1]} {
        position: relative;
        z-index: 0;
        background: none;
        height: auto;
    }
`
