import React, { useRef, useLayoutEffect, useState } from "react"
import styled from "@emotion/styled"
import CarouselImage from "./CarouselImage"
import { get } from "lodash"
import Spacer from "../Spacer"
import { maxW } from "../../../styles/mediaQueries"

const Carousel = ({ imgs }) => {
    const carouselContainer = useRef()

    const [containerSize, setContainerSize] = useState(2406)
    const [windowSize, setWindowSize] = useState(769)
    const [sizeLoaded, loadSize] = useState(false)

    useLayoutEffect(() => {
        setContainerSize(carouselContainer.current.clientWidth)
    }, [])

    const listeningResize = () => {
        setWindowSize(window.innerWidth)
    }

    useLayoutEffect(() => {
        if (!sizeLoaded) {
            setWindowSize(window.innerWidth)
            loadSize(true)
        } else {
            window.addEventListener("resize", listeningResize)
        }

        return () => {
            window.removeEventListener("resize", listeningResize)
        }
    }, [sizeLoaded])

    const ImageList = imgs.map(image => {
        const { hoverText } = image
        const { fluid, id, description } = get(image, "image", {})
        return (
            <CarouselImage
                key={id}
                img={fluid}
                showText={windowSize <= 768}
                copy={hoverText}
                description={description}
            />
        )
    })

    return (
        <>
            <Spacer size="small" />
            <MarqueeDiv>
                <CarouselContainer
                    ref={carouselContainer}
                    carouselLength={containerSize}
                >
                    {ImageList}
                </CarouselContainer>
                {windowSize > 768 && (
                    <CarouselContainer carouselLength={containerSize}>
                        {ImageList}
                    </CarouselContainer>
                )}
            </MarqueeDiv>
        </>
    )
}

export default Carousel

const MarqueeDiv = styled.div`
    display: flex;
    overflow: hidden;
`

const CarouselContainer = styled.div`
    display: flex;
    position: relative;
    animation: scrollingInfinite 45s linear infinite;
    ${maxW[1]} {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        animation: none;
    }
    ${maxW[0]} {
        width: 93%;
    }

    @keyframes scrollingInfinite {
        from {
            transform: translateX(0);
        }
        to {
            transform: ${({ carouselLength }) =>
                `translateX(${carouselLength * -1}px)`};
        }
    }
`
