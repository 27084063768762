import React from "react"
import SEO from "../components/utils/seo"
import ContactUsForm from "../components/Forms/ContactUsForm"
import { graphql } from "gatsby"
import { get } from "lodash"
import { MaxWidthInnerContainer } from "../styles/globalCss"
import {
    HeaderText,
    BodyText,
    UnderlinedTextCTA
} from "../components/Partials/TextPieces"
import BackgroundImage from "../components/Partials/BackgroundImage"
import Carousel from "../components/Partials/Carousel/Carousel"
import CollageTwoRows from "../components/Partials/CollageTwoRows"
import Hero from "../components/Partials/Hero"
import Spacer from "../components/Partials/Spacer"
import styled from "@emotion/styled"
import { maxW } from "../styles/mediaQueries"

const IndexPage = ({ data }) => {
    const { fluid: bkgImg } = get(
        data,
        "contentfulHomepage.backgroundImage",
        {}
    )
    const { description: bkgImgDescription } = get(
        data,
        "contentfulHomepage.backgroundImage"
    )
    const { fluid: heroImg } = get(data, "contentfulHomepage.heroImage")
    const { description: heroImgDescription } = get(
        data,
        "contentfulHomepage.heroImage"
    )
    const { mainHeader } = get(data, "contentfulHomepage")
    const pageDescription = get(
        data,
        "contentfulHomepage.pageDescription.childMarkdownRemark.html"
    )
    const collage = get(data, "contentfulHomepage.collage")
    const { carouselHeader } = get(data, "contentfulHomepage.carousel")
    const carouselDescription = get(
        data,
        "contentfulHomepage.carousel.carouselDescription.childMarkdownRemark.html"
    )
    const carouselImages = get(data, "contentfulHomepage.carousel.images", [])
    // test
    return (
        <>
            <SEO title="Home" />
            <BackgroundImage bkgImg={bkgImg} description={bkgImgDescription} />
            <MaxWidthInnerContainer className="la-coquille-club">
                <Hero img={heroImg} description={heroImgDescription} />
                <HeaderText copy={mainHeader} isH1 />
                <BodyText copy={pageDescription} />
                <Spacer size="small" />
                <UnderlinedTextCTA to="/history">
                    LEARN MORE ABOUT OUR HISTORY
                </UnderlinedTextCTA>
                <Spacer size="small" />
                <CollageTwoRows imgs={collage} />
                <Spacer size="medium" />
                <HeaderText copy={carouselHeader} smallH2 />
                <BodyText copy={carouselDescription} />
            </MaxWidthInnerContainer>
            <Carousel imgs={carouselImages} />
            <MaxWidthInnerContainer>
                <CTAContainer>
                    <UnderlinedTextCTA to="/membership">
                        LEARN MORE ABOUT OUR MEMBERSHIP
                    </UnderlinedTextCTA>
                </CTAContainer>
            </MaxWidthInnerContainer>
            <ContactUsForm bkgImg={bkgImg} description={bkgImgDescription} />
        </>
    )
}

export default IndexPage

export const query = graphql`
    query {
        contentfulHomepage {
            backgroundImage {
                description
                fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            carousel {
                carouselDescription {
                    childMarkdownRemark {
                        html
                    }
                }
                carouselHeader
                images {
                    hoverText
                    image {
                        description
                        fluid(maxWidth: 400, quality: 75) {
                            ...GatsbyContentfulFluid
                        }
                        id
                    }
                }
            }
            collage {
                bottomLeftImage {
                    description
                    fluid(maxWidth: 400, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                bottomRightImage {
                    description
                    fluid(maxWidth: 700, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                topImage {
                    description
                    fluid(maxWidth: 1100, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
            heroImage {
                description
                fluid(maxWidth: 900, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            mainHeader
            pageDescription {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`

const CTAContainer = styled.div`
    margin: 100px 0;
    ${maxW[1]} {
        margin-top: 30px;
    }
`
